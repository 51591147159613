import React from "react";
import { createRoot } from 'react-dom/client';

import * as Sentry from "@sentry/react";

import moment from "moment";
import "moment/locale/nl";
import axios from "axios";
import { stringify } from "qs";

import App from "./App";

import * as serviceWorker from "./serviceWorker";

import ReactGA from "react-ga4";
import {
    clarity
} from "clarity-js";

// Start Sentry if not development environment.
const development = process.env.REACT_APP_ENVIRONMENT === "development";
if(development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://2f7a69374e594eb3a78b361c81b87efe@sentry1.zsnode.com/58",
        beforeSend(event, hint) {
            if (event.exception) {
                // Sentry.showReportDialog({
                //     eventId: event.event_id,
                //     title: "Er is een fout opgetreden.",
                //     subtitle: "De foutmelding is verstuurd.",
                //     subtitle2: "Vul hieronder in wat je deed vlak voor deze melding is verschenen.",
                //     labelName: "Naam",
                //     labelEmail: "Email",
                //     labelComments: "Wat gebeurde er?",
                //     labelClose: "Sluiten",
                //     labelSubmit: "Versturen",
                //     errorGeneric: "Er ging iets fout bij het versturen van je feedback. Probeer het opnieuw.",
                //     errorFormEntry: "Sommige velden zijn ongeldig. Controleer de velden en probeer het opnieuw.",
                //     successMessage: "Je feedback is verstuurd. Bedankt!"
                // });
            }
            return event;
        },
        autoSessionTracking: true,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracePropagationTargets: ["localhost", /^https:\/\/api\.gridsense\.nl/]
    });
}

moment.locale("nl");
moment.tz.setDefault("Europe/Amsterdam");

// Setup Google Analytics
if(development) {
    console.log("Running in development mode. Google Analytics is disabled.");
} else {
    ReactGA.initialize("G-021WB3J2SM");
}

// Setup Microsoft Clarity
if(development) {
    console.log("Running in development mode. Microsoft Clarity is disabled.");
} else {
    clarity.start({
        projectId: "le10i7lkq8",
        upload: "https://m.clarity.ms/collect",
        track: true,
        content: true
    });
}

if(development) {
    axios.defaults.baseURL = "/app/v1/";
} else {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL ? "https://" + process.env.REACT_APP_API_URL + "/app/v1/" : "https://api.gridsense.nl/app/v1/";
}
axios.defaults.withCredentials = true;

axios.defaults.transformRequest = [(data) => {
    data = stringify(data);
    return data;
}];

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
